import React, { forwardRef, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';


const StyledNavbar = styled.div`
    position: fixed;
    top: 0;
    display: flex;
    padding: 1rem;
    width: 100%;
    color: black;
    z-index: 1;
    background-color: white;
    border-bottom: 1px solid purple;
`;

const Logo = styled.img`
    margin-right: auto;
    margin-left: 1rem;
    height: 3.2rem;
    width: auto;
`;

const NavLink = styled.a`
    text-decoration: none;
    color: purple;
    cursor: pointer;
    margin: auto 1rem auto;

    &:hover{
        text-decoration: underline;
    }
`;

const Socials = styled.div`
    margin: auto 1rem auto;

    img {
      width: 31px;
      height: auto;
      margin-top: 0.4rem;
    }
`

function Navbar({isMobile, servicesRef, benefitsRef}, ref) {

  const location = useLocation()
  const handleServicesClick = useCallback((e) => {
    servicesRef.current.scrollIntoView({behavior: 'smooth'})
  }, [])

  const handleBenefitsClick = useCallback((e) => {
    benefitsRef.current.scrollIntoView({behavior: 'smooth'})
  },[])

  useEffect(() => {
    if (location.pathname !== '/'){
      servicesRef.current = null;
      benefitsRef.current = null
    }
    console.log(location.pathname, servicesRef, benefitsRef)
  }, [location])

  const displayLinks = useCallback(() => {
    if (location.pathname === '/'){
      return <>
        <NavLink onClick={handleServicesClick}>Services</NavLink>
        <NavLink onClick={handleBenefitsClick}>Avantages</NavLink>
      </>
    }

    return <Link className='navlink' to="/">Accueil</Link>
  }, [location])
  

  return (
    <StyledNavbar ref={ref}>
        <Logo alt='logo' src='/logo.jpeg'/>
      {displayLinks()}
        <Socials>
              <a href='https://www.facebook.com/gadvf973/' target='_blank' rel="noopener noreferrer">
                <img src='/facebook.png' alt='facebook'  />
              </a>
        </Socials>
    </StyledNavbar>
  );
}

export default forwardRef(Navbar);
