import React from 'react'
import { styled } from 'styled-components'

const StyledServiceItem = styled.div`
    display: flex;
    padding: 0 1rem 1rem 1rem;
    font-size: 20px;

    ${({$isMobile}) => $isMobile && `
        font-size: 11px;
    ` }

    div {
      margin: auto;
    }

    img {
      width: 50%;
      border-radius: 10px;
    }
`

const ServiceItem = ({service, index, isMobile}) => {

  return (
    <StyledServiceItem $isMobile={isMobile}>
      {(index % 2) !== 0 &&
        <>
          <img src={service.image} alt={service.title} />
          <div>
            <h3>{service.title}</h3>
            <ul>
              {service.elements.map((item) =>
                <li key={item}>{item}</li>
              )}
            </ul>
            <br />
          </div>
        </>
      }
      {
        (index % 2) === 0 &&
        <>
          <div>
            <h3>{service.title}</h3>
            <ul>
              {service.elements.map((item) =>
                <li key={item}>{item}</li>
              )}
            </ul>
          </div>
          <img src={service.image} alt={service.title} />
        </>
      }
    </StyledServiceItem>
  )
}

export default ServiceItem