import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const StyledFooter= styled.div`
    background-color: #574f4f;
    width: 100%;
    display: flex;
    color: white; 
    padding: 1rem;

    ${({$isMobile}) => $isMobile && `
        flex-direction: column;
    `}
`

const FooterBlock = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;

    a { 
        color: white;
    }

    h3 {
        margin-top: 0;
        color: white !important;
    }
    ul {
        padding-left: 0;
        margin-top: 0;
    }
    li {
        list-style-type: none;
    }

    p {
      text-align: left;

      ${({ $isMobile }) => !$isMobile && `
        margin: auto;
      `}
    }


    ${({ $isMobile }) => $isMobile && `
        width: 100%;
        text-align: left;
        padding: 1rem;
    `}

`


function Footer({isMobile}, ref) {
  return (
    <StyledFooter $isMobile={isMobile} ref={ref}>
          <FooterBlock $isMobile={isMobile}>
            <h3>Nous écrire</h3>
              <ul>
                  <li><a href="mailto:gadfv973@orange.fr">gadfv973@orange.fr</a></li>
              </ul>
              
        </FooterBlock>
          <FooterBlock $isMobile={isMobile}>
            <h3>Téléphone</h3>
            <ul>
                <li><a href="tel:05 94 38 49 73">0594 38 49 73</a></li>
                <li><a href="tel:06 94 40 56 76">0694 40 56 76</a></li>
                <li><a href="tel:06 94 22 56 96">0694 22 56 96</a></li>
                <li><a href="tel:06 94 29 83 43">0694 29 83 43</a></li>
            </ul>
          </FooterBlock>
          <FooterBlock $isMobile={isMobile}>
              <h3>Nous trouver</h3>
              <p>
                Res. Moutouchi, local N°2
                <br />
                1 Avenue Michel SAID
                <br />
                97351 Matoury
              </p>
                
          </FooterBlock>
          <FooterBlock $isMobile={isMobile}>
              <h3>Liens utiles</h3>
              <ul>
                <li><Link to="/mentions-legales">Mentions légales</Link></li>
              </ul>
          </FooterBlock>

    </StyledFooter>
  );
}

export default forwardRef(Footer);
