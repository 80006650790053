import React, { useEffect, useMemo, useRef, useState } from 'react';
import './App.css';
import {
  Footer, Navbar,
} from './components';
import AppContext from './contexts/appContext';
import { useMediaQuery } from 'react-responsive';
import deviceSize from './responsive';
import CookieConsent from 'react-cookie-consent';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Legals from './pages/Legals';

function App() {

  const servicesRef = useRef(null);
  const benefitsRef = useRef(null);

  const navbarRef = useRef(null);
  const footerRef = useRef(null);


  const [navbarHeight, setNavbarHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  
  const value = useMemo(() => ({navbarHeight, footerHeight}), [navbarHeight, footerHeight])

  useEffect(() => {
    if (navbarRef.current){
      setNavbarHeight(navbarRef.current.offsetHeight)
    }
    if (footerRef.current) {
      setFooterHeight(footerRef.current.offsetHeight)
    }
  }, [navbarRef.current, footerRef])

  const isMobile = useMediaQuery({maxWidth: deviceSize.mobile})
  
  return (
    <AppContext.Provider value={value}>
    <div className="App">
        <Navbar isMobile={isMobile} ref={navbarRef} servicesRef={servicesRef} benefitsRef={benefitsRef}/>

        <Routes>
          <Route  path='/' element={
          <Home isMobile={isMobile} servicesRef={servicesRef} 
            benefitsRef={benefitsRef}
          />} />
          <Route path='/mentions-legales' element={<Legals isMobile={isMobile} />} />
        </Routes>
        

        <CookieConsent
          buttonText="J'ai compris"
        >Ce site utilise des cookies pour améliorer l'expérience utilisateur</CookieConsent>
        <Footer isMobile={isMobile}  ref={footerRef}/>
      </div>
    </AppContext.Provider>
  );
}

export default App;
