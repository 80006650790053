import React, { forwardRef, useContext } from 'react';
import { styled } from 'styled-components';
import { services } from '../constants';
import AppContext from '../contexts/appContext';
import ServiceItem from './ServiceItem';

const StyledServices = styled.div`
    padding: 3rem 5rem 0 5rem;

    ul {
      padding-left: 0;
    }
    li {
        list-style-type: none;        
    }
    scroll-margin-top: ${(props) => `${props.$navbarHeight/3}px`};

    ${({ $isMobile }) => $isMobile && `
        padding: 3rem 1rem 0 1rem;

    `}
`;

const ServicesList = styled.div`
    display: flex;
    flex-direction: column;

    ${({$isMobile}) => $isMobile && `
        text-align: center;
        margin: auto;
    `}


        margin: auto;

    line-height: 1.5;
`;



function Services({isMobile}, ref) {

  const {navbarHeight} = useContext(AppContext);

  return (
    <StyledServices ref={ref} $navbarHeight={navbarHeight} $isMobile={isMobile}>
        <h2 id="services">Services</h2>
        <ServicesList $isMobile={isMobile}>
            {services.map((service, index) => (
                <ServiceItem key={service.title} service={service} index={index+1} isMobile={isMobile}/>
            ))}
        </ServicesList>
       

    </StyledServices>
  );
}

export default forwardRef(Services);
