export const services = [
    {
        title: 'Maison & jardin',
        image: '/gardening.png',
        elements: [
            "Ménage, repassage",
            "Entretien du linge",
            "Préparation de repas",
            "Jardinage, bricolage",
            "Entretien de logement"
        ] ,
    },
    {
        title: 'Âge et dépendance',
        image: '/caring.png',
        elements: [
            "Assistance aux personnes dépendantes",
            "Livraison de courses",
            "Démarches administratives",
            "Assistance administrative"
        ],
    },
    {
        title: 'Garde d’enfants',
        image: '/babysitting.png',
        elements: [
            "Garde d’enfants à domicile",
            "Toilette",
            "Préparation de repas",
            "Aide à l’éveil",
            "Aide à la mobilité",
        ],
    }
]

export const avantages = [
    {
        title: "Crédit d'impôts",
        description: "Bénéficiez de la déduction fiscale de 50%. GADVF vous remet une attestation vous permettant de déduire directement du montant de vos impôts 50% des sommes des prestations effectuées durant l’année."
    }
]

export const legalMentions = [
  "Ce site est la propriété exclusive de la société EURL G.A.D.V.F, SAS au capital 1000,00 euros, immatriculée au RCS de Cayenne sous le numéro B 752 376 574",
  "Le siège social est situé au 1 AVENUE MICHEL SAID 97351 MATOURY (Tél : 0694 40 56 76)",
  "Mail: gadvf973@orange.fr",
  "TVA: FR68752376574",
  "L’hébergement de ce site est assuré par la société HOSTINGER INTERNATIONAL LTD, dont le siège social se situe 61 Lordou Vironos Street, 6023 Larnaca, Chypre, joignable par le moyen suivant : https://www.hostinger.fr/contact.",
  "Le site peut faire usage de cookies qui ont pour but d’améliorer le service des utilisateurs et seront éventuellement utilisés à cette seule fin."
]