import React, { useContext } from 'react';
import AppContext from '../contexts/appContext';
import { styled } from 'styled-components';

const StyledHero = styled.div`
  color: black;

  img {
    height: 8rem;
  }
  ${({ $isMobile }) => $isMobile && `
      h1 {
        padding: 0 1rem 0 1rem;
      }

      img {
        height: 6rem;
      }
  `}

`;

const HeroText = styled.div`
  display: flex;
  margin: auto;
  text-align: left;
  padding: 0 1rem 2rem 1rem;

  ${({$isMobile}) => $isMobile && `
    flex-direction: column;
    padding: 0;
    margin: auto;
  `}
`

const HeroBlock = styled.div`
  flex-direction: column;
  padding: 1rem;

  ${({ $isMobile }) => $isMobile && `
    width: 75%;
    margin: auto;

    padding: 0 2rem 1rem 2rem;

    p {
      width: 100%;
    }
  `}
`

function Hero({isMobile}) {
  const {navbarHeight} = useContext(AppContext);

  return (
    <StyledHero $navbarHeight={navbarHeight} $isMobile={isMobile}>
      <h1>Gaspard Assistance De Vie aux Familles</h1>
      <HeroText $isMobile={isMobile}>
        <HeroBlock $isMobile={isMobile} className='primary-text'>
          <h3>Le spécialiste des services à domicile</h3>
          <p>
            Bénéficiez de l’expérience d’intervenants qualifiés à votre service, et au service de vos proches.
            G.A.D.V.F est votre prestataire de confiance.
          </p>

        </HeroBlock>
        
        <HeroBlock $isMobile={isMobile} className='secondary-text'>
          <h3>Un service reconnu en Guyane</h3>
          <p>
            Des prestations 24h/24 et 7j/7 de qualité, agréées par la préfecture de Guyane.
            Profitez de la qualité de nos services au quotidien.
          </p>
        </HeroBlock>

      </HeroText>

      <img src='/charte_qualite.webp' alt='charte qualité' />

    </StyledHero>
  );
}

export default Hero;
