import React, { forwardRef, useContext } from 'react'
import { styled } from 'styled-components'
import AppContext from '../contexts/appContext';

const StyledBenefits = styled.div`
    padding: 3rem 5rem 0 5rem;
    scroll-margin-top: ${(props) => `${props.$navbarHeight}px`};

    img {
        max-width: 100%;
        height: auto;
    }

    ${({ $isMobile }) => $isMobile && `
        padding: 3rem 1rem 0 1rem;

    `}

    p {
      text-align: left;
    }
`;

const Benefits = ({isMobile}, ref) => {

  const {navbarHeight} = useContext(AppContext);

  return (
    <StyledBenefits ref={ref} $navbaHeight={navbarHeight} $isMobile={isMobile}>
        <h2>Avantages</h2>
        <p>
              Bénéficiez de la <b>déduction fiscale de 50%</b>.
              GADVF vous remet une attestation vous permettant de déduire directement du montant de vos impôts 50% des sommes des prestations effectuées durant l’année.
        </p>
          <img height={240} src='/deduction-fiscale.jpg' alt='déducton fiscale' />

    </StyledBenefits>
  )
}

export default forwardRef(Benefits)