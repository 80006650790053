import React, { useContext, useEffect } from 'react'
import { Hero, Services } from '../components'
import Benefits from '../components/Benefits'
import { styled } from 'styled-components'
import AppContext from '../contexts/appContext'

const Banner = styled.img`
  width: 100%;
  margin-top:${(props) => `${props.$navbarHeight}px`};
`

const Home = ({isMobile, servicesRef, benefitsRef}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "GADVF | Assitance de vie aux familles en Guyane";
  }, [])
  const {navbarHeight} = useContext(AppContext);

  return (
    <div>
      <div>
        <Banner $navbarHeight={navbarHeight} src='/banniere.jpg' alt='bannière' />
      </div>
      <Hero isMobile={isMobile} />
      <Services isMobile={isMobile} ref={servicesRef} />
      <Benefits isMobile={isMobile} ref={benefitsRef} />

    </div>
  )
}

export default Home