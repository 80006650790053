import React, { useContext, useEffect } from 'react'
import { legalMentions } from '../constants'
import { styled } from 'styled-components'
import AppContext from '../contexts/appContext'

const StyledLegals = styled.div`
  padding: 2rem;
  text-align: left;
  padding-top:${(props) => props.$navbarHeight}px ;
  height: calc(100vh - ${(props) => props.$offset }px);

  ${(props) => props.$isMobile && `
  height: 100%;
  `}
  line-height: 2.3;

  li {
    list-style-type: none;
    margin-bottom: 1rem;
  }

`


const Legals = ({isMobile}) => {

  useEffect(() => {
    document.title="Mentions légales | GADVF"
  }, [])
  

  const {navbarHeight, footerHeight} = useContext(AppContext);

  return (
    <StyledLegals $isMobile={isMobile} $navbarHeight={navbarHeight} $offset={navbarHeight + footerHeight}>
      <h1>Mentions légales</h1>
      <ul>
        {legalMentions.map((mention) => (
          <li key={mention}>{mention}</li>
        ))}
      </ul>

    </StyledLegals>
  )
}

export default Legals